// src/About.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './About.css';

function About() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/');
    };

    return (
        <div className="about">
            <div className="about-section">
                <h2>ABOUT DUCKWORTH-DECODED</h2>
                <p>Welcome to Duckworth-Decoded! My name is Rishabh Joshi, and I've been an ardent fan of Kendrick Lamar Duckworth since 2016. Inspired by his exceptional lyricism and profound impact on the rap industry, I've created this platform to share his remarkable story with the world. My mission is to delve deep into Kendrick Lamar's discography, analyzing his music from his earliest mixtapes to his latest releases. Here, I'll explore the meaning behind each song and its connection to Kendrick Lamar's life, offering insights into the artistry of one of the most influential rappers of our time.</p>
            </div>
            <div className="additional-info-section">
                <video className="background-video" autoPlay muted loop>
                    <source src={`${process.env.PUBLIC_URL}/video/about/aboutvideo.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="info-content">
                    <h3>Additional Information</h3>
                    <p>
                        On Duckworth-Decoded, I'll take you on a journey through Kendrick Lamar's entire discography, meticulously analyzing the lyrics and the narratives they weave. Each song will be explored for its lyrical brilliance and the story it tells. I'll highlight the best quotes from each track and share my personal experiences with every album. Additionally, I'll provide background context, historical influences, and the cultural significance of Kendrick's work.
                    </p>
                    <p>
                        Furthermore, I will:
                        <ul>
                            <li>- Break down the themes and concepts within each album.</li>
                            <li>- Examine Kendrick's collaborations and their impact on his music.</li>
                            <li>- Discuss the evolution of his musical style over the years.</li>
                            <li>- Offer insights into the production and creative processes behind his songs.</li>
                            <li>- Compare and contrast Kendrick's work with other influential artists in the industry.</li>
                        </ul>
                    </p>
                    <p>
                        Join me as we decode the genius of Kendrick Lamar, celebrating his contribution to music and his enduring legacy. Let's uncover the layers of meaning in his art and appreciate the depth of his storytelling.
                    </p>
                    <motion.button
                        className="dive-right-in-btn"
                        onClick={handleButtonClick}
                        whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                        whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
                    >
                        Dive Right In
                    </motion.button>
                </div>
            </div>
        </div>
    );
}

export default About;
