import React, { useEffect } from 'react';
import './LoadingScreen.css';

function LoadingScreen({ isLoading }) {
    useEffect(() => {
        // Control body scroll based on loading state
        document.body.style.overflow = isLoading ? 'hidden' : 'auto';
    }, [isLoading]);

    return (
        <div className={`loading-screen ${isLoading ? 'visible' : ''}`}>
            <div className="logo">
                <h1>DCKWRTH</h1>
            </div>
        </div>
    );
}

export default LoadingScreen;
