import { useState, useEffect } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import About from "./Albums/About/About";
import Home from "./Albums/Home/Home";
import LoadingScreen from "./Albums/util/LoadingScreen/LoadingScreen";
import { debounce } from 'lodash';
import { motion } from "framer-motion";
import './App.css';
import SearchResults from "./Albums/Home/SearchResults";

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        let lastScrollY = window.scrollY;
        const handleScroll = debounce(() => {
            const currentScrollY = window.scrollY;
            setShowHeader(currentScrollY <= lastScrollY);
            lastScrollY = currentScrollY;
        }, 100);

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    };

    return (
        <div className="App">
            <LoadingScreen isLoading={isLoading} />
            {!isLoading && (
                <>
                    <header className={`App-header ${showHeader ? '' : 'hide'}`}>
                        <div className="header-content">
                            <h1><Link to="/" className="logo-link">DCKWRTH</Link></h1>
                            <nav className="nav-links">
                                <Link to="/">Home</Link>
                                <Link to="/about">About</Link>
                            </nav>
                            <form onSubmit={handleSearchSubmit}>
                                <motion.input
                                    type="text"
                                    className="search-bar"
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    whileFocus={{ scale: 1.05, backgroundColor: "#fff" }}
                                    transition={{ duration: 0.2 }}
                                />
                            </form>
                        </div>
                    </header>
                    <main>
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/search" element={<SearchResults />} />
                        </Routes>
                    </main>
                    <footer>
                        <p>© 2024 Duckworth Decoded. All rights reserved.</p>
                    </footer>
                </>
            )}
        </div>
    );
}

export default App;