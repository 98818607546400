import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Home.css';
import TheKendrickLamarEP from "../TheKendrickLamarEP/TheKendrickLamarEP";
import OverlyDedicated from "../OverlyDedicated/OverlyDedicated";

function Home() {
    const navigate = useNavigate();

    const handleLearnMoreClick = () => {
        navigate('/about');
    };

    const handleLetsStartClick = () => {
        navigate('/#theKendrickLamarEP');
        setTimeout(() => {
            const element = document.getElementById('track1');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    return (
        <main>
            <title>Duckworth Decoded</title>
            <section className="hero">
                <div className="hero-content">
                    <h2>DUCKWORTH</h2>
                    <h2>DECODED.</h2>
                </div>
                <div className="hero-video">
                    <video autoPlay muted loop>
                        <source src={`${process.env.PUBLIC_URL}/video/home/welcomevideo.mp4`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="hero-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/home/kendrickonstairs.jpg)` }}></div>
            </section>
            <section className="welcome">
                <div className="welcome-content">
                    <h2>WELCOME TO DUCKWORTH-DECODED!</h2>
                    <p>Discover the Legacy of Kendrick Lamar: A Comprehensive Website on the World-Renowned Artist</p>
                    <motion.button
                        className="learn-more-btn"
                        onClick={handleLearnMoreClick}
                        whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                        whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
                    >
                        Learn More
                    </motion.button>
                </div>
            </section>
            <section className="image-section">
                <div className="image-container" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/home/comptonpolitics.jpeg)` }}></div>
            </section>
            <section className="quote-section">
                <p>
                    "LIFT UP YOUR HEAD AND KEEP MOVING, <br />
                    OR LET THE PARANOIA HAUNT YOU?"
                </p>
                <span>– Kendrick Lamar, "i"</span>
            </section>
            <section className="new-section">
                <div className="new-section-content">
                    <h2>Welcome, friends!</h2>
                    <div className="title-line-home"></div>
                    <p>Welcome to Duckworth-Decoded, your ultimate resource for exploring Kendrick Lamar's discography. Here, I meticulously analyze his lyricism and storytelling, delving deep into the themes and narratives woven throughout his music. I'll be regularly updating the website with insights and breakdowns of each single and album as I progress through his body of work. Thank you for joining me on this journey!</p>
                    <p>As of today (May 22, 2024), Kendrick Lamar has released 5 studio albums and 71 singles. My goal is to cover as much of his music as possible to bring people closer to his works of art.</p>
                    <motion.button
                        className="lets-start-btn"
                        onClick={handleLetsStartClick}
                        whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                        whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
                    >
                        Let's start
                    </motion.button>
                </div>
            </section>
            <TheKendrickLamarEP />
            <OverlyDedicated />
        </main>
    );
}

export default Home;
