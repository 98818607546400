import React from 'react';
import { useSearchParams } from 'react-router-dom';

const SearchResults = () => {
    let [searchParams] = useSearchParams();
    const query = searchParams.get('query');

    // Simulate fetching data based on query
    const fetchDataBasedOnQuery = (query) => {
        // Implement your search logic here
        // Return results as an array of elements
        return [
            { id: 1, name: "Result 1 for " + query },
            { id: 2, name: "Result 2 for " + query }
        ];
    };

    const results = fetchDataBasedOnQuery(query);

    return (
        <div>
            <h2>Search Results for: {query}</h2>
            {results.length > 0 ? (
                <ul>
                    {results.map((result) => (
                        <li key={result.id}>{result.name}</li>
                    ))}
                </ul>
            ) : (
                <p>No results found.</p>
            )}
        </div>
    );
};

export default SearchResults;
