import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ReviewSection = ({ children, title }) => {
    const [isOpen, setIsOpen] = useState(localStorage.getItem('reviewSectionOpen') === 'true');

    useEffect(() => {
        localStorage.setItem('reviewSectionOpen', isOpen);
    }, [isOpen]);

    const toggleSection = () => setIsOpen(!isOpen);

    return (
        <div className="review-section">
            <div className="review-header">
                <h2>{title}</h2>
                <button className="toggle-btn" onClick={toggleSection}>
                    {isOpen ? '-' : '+'}
                </button>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ReviewSection;
