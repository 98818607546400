import AlbumSection from '../util/AlbumSection';
import TrackSection from '../util/TrackSection';
import ReviewSection from "../util/ReviewSection";
import { motion } from 'framer-motion';
import './TheKendrickLamarEP.css';

function TheKendrickLamarEP() {
    const albumCover = `${process.env.PUBLIC_URL}/images/albumcovers/kendricklamarEP.jpg`;

    return (
        <section className="ep-section" id="theKendrickLamarEP">
            <div className="ep-content">
                <AlbumSection title="Kendrick Lamar EP (2009)" coverImage={albumCover}>
                    <div className="title-line"></div>
                    <div className="ep-info">
                        <img src={albumCover} alt="Kendrick Lamar EP" className="ep-image" />
                        <div className="ep-text">
                            <p>Welcome to the first ever entry of Duckworth-Decoded. We’ll be taking a look at
                                one of Kendrick Lamar’s earliest releases, the self-titled <b>Kendrick Lamar</b> EP.
                                Released in 2009, this EP is a raw and intimate glimpse into the budding artistry of a young rapper
                                from Compton who would go on to redefine the genre.</p>
                            <p>Join me as we explore each track, uncovering the stories, themes, and lyrical genius that
                                lay the foundation for Kendrick’s meteoric rise to greatness.</p>
                        </div>
                    </div>
                    {/* Repeat for each track as follows: */}
                    <TrackSection title="Track 1: Is It Love (feat. Angela McCluskey)" trackId="track1">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>This track sets the tone for the EP with its introspective lyrics and soulful sample from
                                Telepopmusik's "Don't Look Back."</p>
                            <div className="track-buttons">
                                <motion.a href="https://genius.com/Kendrick-lamar-is-it-love-lyrics" className="track-button"
                                          target="_blank" rel="noopener noreferrer"
                                          whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                                          whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}>
                                    Lyrics
                                </motion.a>
                                <motion.a href="https://www.youtube.com/watch?v=RuRxLQxj2g4" className="track-button"
                                          target="_blank" rel="noopener noreferrer"
                                          whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                                          whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}>
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"WHEN LOVE COMES CALLING, DON'T LOVE BACK<br/>
                                    WHEN LOVE COMES CALLING, DON'T LOOK AWAY."</p>
                                <span>– Kendrick Lamar, "Is It Love"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 2: Celebration" trackId="track2">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>"Celebration" is exactly what it sounds like—a triumphant anthem where Kendrick calls for
                                recognition of his hard-earned success. This track embodies a moment of victory amidst
                                his
                                journey, highlighting his desire for validation and acknowledgment from the world.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-celebration-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=DF8QbhoUFGQ"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "PEOPLE DON'T BE SHY <br/>
                                    RELEASE THEM BALLOONS TO THE SKY <br/>
                                    I NEED THE WORLD TO CELEBRATE ME! (ONE TIME) <br/>
                                    YOU'LL NEVER KNOW WHEN I'M GONE (TWO TIMES)."
                                </p>
                                <span>– Kendrick Lamar, "Celebration"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 3: P&P (feat. Ab-Soul)" trackId="track3">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>An alternate version of this track appears on *Overly Dedicated*. It's a raw and honest
                                exploration of escapism through vices. Kendrick candidly discusses how he and those
                                around him
                                use sex and alcohol to cope with life's challenges, revealing a side of his life marked
                                by
                                temporary reliefs from deeper pains.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-p-p-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=cEIMQhyPk0k"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "I'M GOING THROUGH SOMETHING WITH LIFE <br/>
                                    BUT PUSSY AND PATRÓN MAKE YOU FEEL ALRIGHT <br/>
                                    PUSSY AND PATRÓN, THAT'S SOME GREAT ADVICE."
                                </p>
                                <span>– Kendrick Lamar, "P&P"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 4: She Needs Me (feat. Javonte)" trackId="track4">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>A tale of love and need, this track reveals deeper layers of Kendrick's storytelling.
                                Initially,
                                it seems like a simple narrative of a girl needing Kendrick, but the twist comes when
                                it's
                                understood that Kendrick actually needs her. This song reflects on the dynamics of
                                relationships
                                and the hidden dependencies within them.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-she-needs-me-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=_kE-GOYqWsc"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "THAT GIRL IS SMOKING, AND CAN YOU BELIEVE THAT I'M HER FOCUS? <br/>
                                    IT AIN'T HARD TO SEE SHE GOT ME OPEN, <br/>
                                    AND TOGETHER WE ARE ROLLER-COASTING ON."
                                </p>
                                <span>– Kendrick Lamar, "She Needs Me"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 5: I Am (Interlude)" trackId="track5">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>An introspective piece where Kendrick declares his ambition to win hearts before winning
                                a
                                Grammy. This interlude is a testament to his determination to make a meaningful impact
                                through
                                his music, beyond just commercial success.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-i-am-interlude-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=2KVNB1XAqf8"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "MY PLAN B IS TO WIN YA HEARTS BEFORE I <br/>
                                    WIN A GRAMMY."
                                </p>
                                <span>– Kendrick Lamar, "I Am (Interlude)"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 6: Wanna Be Heard" trackId="track6">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>Kendrick's hunger for recognition shines through, as he delivers a masterful blend of
                                storytelling and self-reflection. He addresses his struggles with identity and the
                                pursuit of his dreams, underscored by a beat from Detroit underground legend Black
                                Milk.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-wanna-be-heard-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=1VqTaV3hbvY"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "I USED TO WANNA RAP LIKE JAY-Z UNTIL I <br/>
                                    FINALLY REALIZED THAT JAY WASN'T ME."
                                </p>
                                <span>– Kendrick Lamar, "Wanna Be Heard"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 7: I Do This (feat. Jay Rock)" trackId="track7">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>A confident declaration of his artistic success, this track later faced legal issues due
                                to an uncleared sample. Kendrick and Jay Rock boast about their achievements and their
                                relentless drive to succeed, reflecting the raw energy and confidence of their early
                                careers.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-i-do-this-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=yiel4RTZ-tY"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "BITCH, I DO THIS!"
                                </p>
                                <span>– Kendrick Lamar, "I Do This"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 8: Uncle Bobby & Jason Keaton (feat. Javonte)" trackId="track8">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>A heartfelt track discussing the impacts of prison life and systemic injustices. Kendrick
                                paints a vivid picture of how incarceration affects families and communities,
                                highlighting the personal stories of his uncle and friend to emphasize the broader
                                social issues.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-uncle-bobby-and-jason-keaton-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=iRhfdZGb-nM"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "LIFE'S ABOUT DECISIONS MAN <br/>
                                    IT'S IN YOUR HAND AND YOU GOT IT <br/>
                                    JUST TAKE CONTROL IF YOU CAN <br/>
                                    IT'S IN YOUR HAND AND YOU GOT IT."
                                </p>
                                <span>– Kendrick Lamar, "Uncle Bobby & Jason Keaton"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 9: Faith (feat. BJ the Chicago Kid & Punch)" trackId="track9">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>Kendrick delves into themes of faith and struggle, highlighting the importance of
                                perseverance. He discusses the trials people face and how maintaining faith can be a
                                challenging yet essential part of navigating life's hardships.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-faith-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=27HVvRUMy7E"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "I TAKE A SIP OF HENNESSY AND THEN GET PISSY DRUNK <br/>
                                    I AIN'T A DRINKER, I'M A THINKER, CALL IT WHAT YOU WANT <br/>
                                    BUT IF YOU TURN YOUR BACK, KNOW THAT YOU JUST MISSED YOUR CHANCE <br/>
                                    TO WITNESS THE REALEST SHIT THAT'S EVER BEEN TOLD TO MAN."
                                </p>
                                <span>– Kendrick Lamar, "Faith"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 10: Trip" trackId="track10">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>This song captures Kendrick's feelings of alienation as he tries to make his way into the
                                music industry. He reflects on the challenges of staying true to oneself while seeking
                                acceptance in a world that often demands conformity.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-trip-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=REobv3J3OqU"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "AND I'M A TRIP <br/>
                                    EVER SINCE AN UNDERSTUDY, MAN, I ALWAYS BEEN A TRIP<br/>
                                    MAMA TELL ME THAT SHE LOVE ME BUT SHE KNOW HER SON A TRIP<br/>
                                    BABY, OPEN UP YOUR EARS, YOU AIN'T HEARD IT LIKE THIS."
                                </p>
                                <span>– Kendrick Lamar, "Trip"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 11: Vanity Slaves" trackId="track11">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>Kendrick meditates about the overcompensation of African Americans and how it skews their
                                image. This thought-provoking piece delves into materialism and self-worth, using
                                powerful imagery to convey the strong message behind the word "slave."</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-vanity-slaves-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=_NNQQv-q4po"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "IF YOU EVER GET YOUR FIRST BIG CHECK <br/>
                                    AND YOU COP A CHAIN BEFORE YOU BUY A HOUSE, <br/>
                                    YOU'RE A VANITY SLAVE."
                                </p>
                                <span>– Kendrick Lamar, "Vanity Slaves"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 12: Far From Here (feat. Schoolboy Q)" trackId="track12">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>A song that talks about Kendrick’s struggles in life and his need to escape from his
                                circumstances. Featuring Schoolboy Q, the track highlights their shared experiences and
                                the drive to rise above their environment.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-far-from-here-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=TryLhNmMRUE"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "YOU EVER FEEL LIKE NOBODY EVER UNDERSTANDS YOU BUT YOU? <br/>
                                    NOT YOUR MOMMA, YOUR POPPA, ONLY PERSON IS YOU<br/>
                                    NOT YOUR BROTHER, YOUR SISTER, ONLY PERSON IS YOU<br/>
                                    YOU EVER FEEL LIKE NOBODY EVER UNDERSTANDS YOU BUT YOU?"
                                </p>
                                <span>– Kendrick Lamar, "Far From Here"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 13: Thanksgiving (feat. Big Pooh)" trackId="track13">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>Kendrick talks about his aspirations, work on himself, and his artistic identity. The
                                track emphasizes his achievements and the challenges he faces, with Big Pooh sharing his
                                own life and artistic experiences.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-thanksgiving-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=7ojFn9j_4BQ"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "NOW EVERYBODY JUST BOW TO THE <br/>
                                    GREATNESS OR DIE IN THE MATRIX."
                                </p>
                                <span>– Kendrick Lamar, "Thanksgiving"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 14: Let Me Be Me" trackId="track14">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>This song speaks of external pressure, self-betterment, and believing in oneself when
                                others don’t. It touches on a multitude of other themes, emphasizing Kendrick's
                                determination to stay true to his identity.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-let-me-be-me-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=mvYO9GdXsos"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "JUST LET ME BE ME (HEY, HEY)<br/>
                                    THAT'S THE ONLY WAY I KNOW (ONLY WAY I KNOW)<br/>
                                    JUST LET ME BE ME (HEY, HEY)."
                                </p>
                                <span>– Kendrick Lamar, "Let Me Be Me"</span>
                            </div>
                        </div>
                    </TrackSection>

                    <TrackSection title="Track 15: Determined (feat. Ash Riser)" trackId="track15">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>A motivational track where Kendrick expresses his relentless drive to achieve success
                                despite the odds. It reflects his ambition and the obstacles he faces on his journey to
                                greatness.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-determined-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=m5yLdpttbe0"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>
                                    "I WANT TO DESCRIBE MY DISCOVERY IN THE SIMPLEST TERMS POSSIBLE <br/>
                                    BECAUSE IT WILL REVEAL TO YOU WHY IT IS TRUE<br/>
                                    THAT WHATEVER THE MIND CAN CONCEIVE AND BELIEVE<br/>
                                    THE MIND CAN ACHIEVE<br/>
                                    REGARDLESS OF HOW MANY TIMES YOU MAY HAVE FAILED IN THE PAST<br/>
                                    OR HOW OFF DAY YOUR AIMS AND HOPES MAY BE."
                                </p>
                                <span>– Kendrick Lamar, "Determined"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <ReviewSection title="Summary and Review">
                        <div className="title-line"></div>
                        <div className="review-content">
                            <p className="review-summary">The "Kendrick Lamar" EP serves as a promising early work that
                                showcases Kendrick's potential. While it lacks the polish of his later albums, it is
                                crucial for understanding his artistic development. With compelling storytelling and a
                                heartfelt approach, it's a worthwhile listen for fans interested in the genesis of a
                                hip-hop icon.</p>
                            <div className="review-details">
                                <div className="review-category">
                                    <h3>Best Tracks</h3>
                                    <ul>
                                        <li>Is It Love?</li>
                                        <li>Vanity Slaves</li>
                                        <li>She Needs Me</li>
                                    </ul>
                                </div>
                                <div className="review-category">
                                    <h3>Weakest Track</h3>
                                    <p>I Do This</p>
                                </div>
                                <div className="review-category">
                                    <h3>Personal Favorite</h3>
                                    <p>She Needs Me</p>
                                </div>
                            </div>
                            <div className="review-rating">
                                <h2>Overall Rating:</h2>
                                <span className="rating">87/100 (Grade B+)</span>
                            </div>
                        </div>
                    </ReviewSection>
                </AlbumSection>

            </div>
        </section>
    );
}

export default TheKendrickLamarEP;