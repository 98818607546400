import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const TrackSection = ({ children, title, trackId }) => {
    const [isOpen, setIsOpen] = useState(localStorage.getItem(`trackSectionOpen_${trackId}`) === 'true');

    useEffect(() => {
        localStorage.setItem(`trackSectionOpen_${trackId}`, isOpen);
    }, [isOpen, trackId]);

    const toggleSection = () => setIsOpen(!isOpen);

    return (
        <div className="track-section">
            <div className="track-header">
                <h3>{title}</h3>
                <button className="toggle-btn" onClick={toggleSection}>
                    {isOpen ? '-' : '+'}
                </button>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default TrackSection;
