// src/OverlyDedicated.js
import React from 'react';
import AlbumSection from '../util/AlbumSection';
import TrackSection from '../util/TrackSection';
import ReviewSection from '../util/ReviewSection';
import {motion} from 'framer-motion';
import './OverlyDedicated.css';

function OverlyDedicated() {
    const albumCover = `${process.env.PUBLIC_URL}/images/albumcovers/overlyDedicated.jpg`;

    return (
        <section className="ep-section" id="OverlyDedicated">
            <div className="ep-content">
                <AlbumSection title="Overly Dedicated (2010)" id="overlyDedicated" coverImage={albumCover}>
                    <div className="title-line"></div>
                    <div className="ep-info">
                        <img src={`${process.env.PUBLIC_URL}/images/albumcovers/overlyDedicated.jpg`}
                             alt="Overly Dedicated" className="ep-image"/>
                        <div className="ep-text">
                            <p>Welcome back, friends! Today, we’re diving into Kendrick Lamar's debut album, <b>Overly
                                Dedicated</b>.</p>
                            <p>This mixtape delves deep into personal and social themes, providing a rich tapestry of
                                his experiences and aspirations. Join us as we explore the tracks that highlight
                                Kendrick’s journey from a talented newcomer to a groundbreaking artist, capturing the
                                essence of his early struggles and triumphs.</p>
                        </div>
                    </div>
                    <TrackSection title="Track 1: The Heart Pt. 2 (feat. Dash Snow)" trackId="track1">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>This track is a stream-of-consciousness flow where Kendrick pours out his emotions and
                                thoughts, creating an intense listening experience. He discusses his struggles and
                                dreams, setting a raw, emotional tone for the mixtape.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-the-heart-pt-2-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=xep756J2ffg"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"YOU PROBABLY HEARD I WANNA BE HEARD AND WONDER WHO I AM, <br/>
                                    YOU PROBABLY EVEN LISTENED TO 'FAITH,' AND THINK I KNOW ZION <br/>
                                    BUT REALLY I'M JUST CAUGHT IN THE LOOP, OF UNDERSTANDING THE TRUTH <br/>
                                    BECAUSE IT SEEM LIKE IT'S ALWAYS CLASHING WITH SCIENCE."</p>
                                <span>– Kendrick Lamar, "The Heart Pt. 2"</span>
                            </div>
                        </div>
                    </TrackSection>
                    {/* Additional tracks to be added here */}
                    <TrackSection title="Track 2: Growing Apart (To Get Closer) (feat. Jhené Aiko)" trackId="track2">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>A reflective track about personal growth and the complexities of relationships, featuring
                                the soulful voice of Jhené Aiko. Kendrick explores the painful yet necessary process of
                                growing apart to achieve personal development.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-growing-apart-to-get-closer-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=5IvQHBMx8I8"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"SO IN CONCLUSION, WE ALL SEEM TO STUMBLE, PLANNING OUR OWN DEMISE. <br/>
                                    FORGETTING THE BIG PICTURE AND MAKING IT WALLET SIZE."</p>
                                <span>– Kendrick Lamar, "Growing Apart (To Get Closer)"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 3: Night of the Living Junkies" trackId="track3">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>This track paints a vivid picture of addiction and its impact on individuals and
                                communities. Kendrick's storytelling here is both harrowing and empathetic, shedding
                                light on the dark realities faced by many.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-night-of-the-living-junkies-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=9OIQQ9OU94Y"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"FORGET THE BIG PICTURE AND MAKE IT WALLET SIZE."</p>
                                <span>– Kendrick Lamar, "Night of the Living Junkies"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 4: P&P 1.5 (feat. Ab-Soul)" trackId="track4">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>An updated version of a track from his earlier EP, it maintains the raw honesty about
                                escapism and vices. This version deepens the narrative, highlighting the repetitive and
                                cyclical nature of seeking temporary relief from life's pains.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-p-p-15-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=OKxcybGRbkQ"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"WE OFTEN GET LOST IN THE ALCOHOL BOTTLE, IN AN ATTEMPT TO IGNORE PAIN, PROBLEM, AND
                                    SORROW. <br/>
                                    JUST FOR A MINUTE, THEN BACK TO THE BULLSHIT."</p>
                                <span>– Kendrick Lamar, "P&P 1.5"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 5: Alien Girl (Today With Her)" trackId="track5">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>A catchy track that showcases Kendrick's ability to appeal to a wider audience while
                                maintaining his unique style. He narrates a story of infatuation and the allure of a
                                mysterious woman, using clever wordplay and vivid imagery.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-alien-girl-today-with-her-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=xxGEi4msn8g"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"SHE LOOK BETTER THAN BEYONCÉ, ALICIA KEYS, HALLE BERRY, MISS JOLIE. <br/>
                                    WHERE'S SHE FROM? NO I.D. (THAT GIRL'S AN ALIEN)."</p>
                                <span>– Kendrick Lamar, "Alien Girl (Today With Her)"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 6: Opposites Attract (Tomorrow W/O Her) (feat. JaVonté)"
                                  trackId="track6">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>A deep dive into the psychology of infidelity, told from both the man and woman’s
                                perspective. Kendrick examines the reasons behind cheating and the emotional turmoil it
                                causes, providing a nuanced view of relationship dynamics.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-opposites-attract-tomorrow-w-o-her-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=UXEDr2HpmpI"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"WE HURT PEOPLE THAT LOVE US, LOVE PEOPLE THAT HURT US."</p>
                                <span>– Kendrick Lamar, "Opposites Attract (Tomorrow W/O Her)"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 7: Michael Jordan (feat. ScHoolboy Q)" trackId="track7">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>While this track tries to emulate a certain style, it doesn't quite hit the mark compared
                                to others on the album. It highlights Kendrick's early influences and the learning curve
                                in finding his unique voice.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-michael-jordan-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=OxiPLWaVNYc"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"EVERY TIME I’M IN MY CITY, I BE FEELING LIKE MY SHIT DON’T STINK. <br/>
                                    USED TO CLEAN MY ROLIE CHAIN WITH ALCOHOL IN THE SINK."</p>
                                <span>– Kendrick Lamar, "Michael Jordan"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 8: Ignorance is Bliss" trackId="track8">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>A powerful track that sparked Dr. Dre’s interest in Kendrick, showcasing his lyrical
                                prowess. Kendrick addresses the harsh realities of street life and the ignorance that
                                often accompanies it, making a bold statement about his own awareness and growth.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-ignorance-is-bliss-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=xiU8xDMOXfo"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"MAKE AN ALBUM THAT'LL PUT A SMILE ON MALCOLM, MAKE MARTIN LUTHER TELL GOD I'M THE
                                    FUTURE FOR HEAVEN'S TALENT."</p>
                                <span>– Kendrick Lamar, "Ignorance is Bliss"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 9: R.O.T.C (Interlude) (feat. BJ the Chicago Kid)" trackId="track9">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>An introspective track where Kendrick contemplates his career and life choices. He
                                reflects on the sacrifices and uncertainties of pursuing his dreams, questioning whether
                                his path will lead to success or failure.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-rotc-interlude-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=Pd7th3YceoU"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"SOMETIMES I WANNA SAY FUCK RAPPING, I NEED MONEY NOW. <br/>
                                    LIKE SHOULD I START TRAPPING?"</p>
                                <span>– Kendrick Lamar, "R.O.T.C (Interlude)"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 10: Barbed Wire (feat. Ash Riser)" trackId="track10">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>A poignant offering that uses vivid lyricism to depict life's adversities and triumphs.
                                Kendrick and Ash Riser paint a stark picture of overcoming obstacles and breaking
                                through barriers, using the metaphor of barbed wire to represent life's challenges.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-barbed-wire-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=_oAJJHQVDmg"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"SO WHILE I HOLD THIS BOTTLE OF CIROC WITH A HAND FULL OF ASS, <br/>
                                    CELEBRATE THE VERY DAY THAT I GET PAST THROUGH THE BARBED WIRE."</p>
                                <span>– Kendrick Lamar, "Barbed Wire"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 11: Average Joe" trackId="track11">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>Kendrick drops commentary on Compton, his near-death experiences, and himself. He
                                provides a raw, unfiltered look at his life and the environment that shaped him,
                                emphasizing the ordinary struggles of an average person in his community.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-average-joe-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=0jHV0HxQQGw"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"I'M NO GANGSTER, NO KILLER, I'M JUST YOUR AVERAGE JOE. <br/>
                                    BUT ONE THING YOU SHOULD CONSIDER, I'M THE REALEST YOU KNOW."</p>
                                <span>– Kendrick Lamar, "Average Joe"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 12: H.O.C." trackId="track12">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>Kendrick talks about his experiences as a non-smoker and how his music might appeal to
                                potheads despite him not indulging. This track highlights his unique perspective and
                                authenticity in an industry often dominated by certain stereotypes.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-hoc-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=pB60qaZf97A"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"BET YOU THINK THAT THIS SOME HIGH SHIT THAT I WROTE, PROBABLY THINK I'M OFF THE KUSH
                                    OR THE HYDRO. (NOPE) <br/>
                                    I DON'T EVEN SMOKE, I DON'T EVEN SMOKE."</p>
                                <span>– Kendrick Lamar, "H.O.C."</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 13: Cut You Off (To Grow Closer)" trackId="track13">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>Kendrick spits about trying to grow as an ambitious person while dealing with negative
                                influences that hold him back. He emphasizes the importance of surrounding oneself with
                                positive, inspiring people to achieve personal and professional growth.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-cut-you-off-to-grow-closer-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=ZJzr2Dsputk"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"I'M TRYNA LEARN SOMETHING NEW, I'M TRYNA SURROUND MYSELF WITH PEOPLE THAT INSPIRE
                                    ME <br/>
                                    OR AT LEAST INQUIRE SIMILAR DESIRES TO DO WHAT IT T-A-K-E JUST TO REACH THE T-O-P."
                                </p>
                                <span>– Kendrick Lamar, "Cut You Off (To Grow Closer)"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 14: Heaven & Hell (feat. Alori Joh)" trackId="track14">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>This track contrasts the negative and positive aspects of life, representing
                                Hell-on-Earth and Kendrick’s idea of paradise. The shorter second verse alludes to the
                                imbalance of good and evil in the world.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-heaven-and-hell-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=SpScHR13QJI"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"NIGGAS HATIN', BITCHES HATIN', BABIES DYIN' / BULLETS FLYIN', HELICOPTERS, POLICE
                                    SIRENS, PREACHERS LYING <br/>
                                    GENOCISM, CRITICISM, UNEMPLOYMENT, RACISM / BURNING BUILDINGS, AIDS VICTIMS, CANCER
                                    KILLIN', NO CURE."</p>
                                <span>– Kendrick Lamar, "Heaven & Hell"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 15: She Needs Me (Remix) (feat. DOM KENNEDY, JaVonté & Murs)"
                                  trackId="track15">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>A remixed version from Kendrick’s previous EP, this track dives deeper into the
                                complexities of relationships and dependency, offering new perspectives from DOM KENNEDY
                                and Murs.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-she-needs-me-remix-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=WR5k-huT4uI"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"REMEMBER DINNER DATES I WAS LATE? MR. CHOWS / TRUANT LIKE A STUDENT, YOU THE
                                    PRINCIPAL <br/>
                                    SCHOOLED ME ON TIMING, WE WAS BOTH SHINING / LIKE THE SUN BOUNCING OFF THE BRIGHTEST
                                    BLOOD DIAMOND."</p>
                                <span>– Kendrick Lamar, "She Needs Me (Remix)"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <TrackSection title="Track 16: I Do This (Remix) (feat. Brown, Skeme & U-N-I)" trackId="track16">
                        <div className="track-content">
                            <div className="title-line"></div>
                            <p>The official remix for “I Do This” with additional verses from Brown, Skeme, and U-N-I.
                                This track maintains the boastful energy of the original while adding new dimensions
                                through its featured artists.</p>
                            <div className="track-buttons">
                                <motion.a
                                    href="https://genius.com/Kendrick-lamar-i-do-this-remix-lyrics"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Lyrics
                                </motion.a>
                                <motion.a
                                    href="https://www.youtube.com/watch?v=bgNCP1p84iQ"
                                    className="track-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    whileHover={{scale: 1.05, transition: {duration: 0.2}}}
                                    whileTap={{scale: 0.95, transition: {duration: 0.1}}}
                                >
                                    Listen
                                </motion.a>
                            </div>
                            <div className="featured-quote">
                                <h4>Featured Quote:</h4>
                                <p>"BITCH I DO THIS REMIX STUPID, FIRE BURNING, PEOPLE LOOTING / THAT'S THE CHAOS, COMES
                                    FROM K. DOT <br/>
                                    SWINGING SWORDS AND HANGING NOOSES"</p>
                                <span>– Kendrick Lamar, "I Do This (Remix)"</span>
                            </div>
                        </div>
                    </TrackSection>
                    <ReviewSection title="Summary and Review">
                        <div className="title-line"></div>
                        <div className="review-content">
                            <p className="review-summary"><b>Overly Dedicated</b> is an interesting listen, showcasing
                                Kendrick Lamar's growth and maturity as an artist. The album is a blend of abstract
                                beats, powerful lyrics, and unique delivery, making it a fascinating precursor to his
                                future works.</p>
                            <div className="review-rating">
                                <h2>Overall Rating:</h2>
                                <span className="rating">83/100 (Grade B)</span>
                            </div>
                        </div>
                    </ReviewSection>
                </AlbumSection>
            </div>
        </section>
    );
}

export default OverlyDedicated;
