import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const AlbumSection = ({ children, title, coverImage }) => {
    const [isOpen, setIsOpen] = useState(localStorage.getItem('albumSectionOpen') === 'true');

    useEffect(() => {
        localStorage.setItem('albumSectionOpen', isOpen);
    }, [isOpen]);

    const toggleSection = () => setIsOpen(!isOpen);

    return (
        <div className="album-section">
            <div className="album-header" onClick={toggleSection}>
                {!isOpen && <img src={coverImage} alt="Album Cover" className="album-cover" />}
                <h2>{title}</h2>
                <button className="toggle-btn">
                    {isOpen ? '-' : '+'}
                </button>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default AlbumSection;